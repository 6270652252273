.outline {
    margin-top: 5rem;
    position: sticky;
    top: 2rem;
    align-self: flex-start;
    font-style: italic;
    opacity: 0.5;
}

@media (max-width: 1100px) {
    .outline {
        display: none; /* or your preferred display property */
    }
}