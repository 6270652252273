body {
  margin: 0;
  background-color: #ECF0F1;
  font-family: 'Source Serif Pro', serif;
}

* {
  box-sizing: border-box;
}

h1 {
  font-family: 'Source Serif Pro', serif;
  font-weight: 100;
  font-size: 3rem;
}

h2 {
  font-family: 'Source Serif Pro', serif;
  font-weight: 100;
  font-size: 2rem;
}

p {
  font-family: 'Assistant', sans-serif;
}

a {
  text-decoration: none;
}

p a {
  color: #8A9093;
  text-decoration: underline;
}

p a:visited {
  color: #404040;
}


/* header */

header {
  z-index: 2;
  position: fixed;
  width: 100%;
  color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 10%;
  border-bottom: 0.5px solid #707070;
  background-color: #ECF0F1;
  opacity: 0.9;
}

#name, .header-links {
  font-family: 'Source Serif Pro', serif;
  font-weight: 100;
}

#name a {
  color: black !important;
}

.header-links {
  list-style: none;
  font-size: 1.3rem;
}

.header-links li {
  display: inline-block;
  padding: 6px 15px;
}

.link {
  color: black;
  display: inline;
  position: relative;
  overflow: hidden;
}

.link-current {
  color: black;
  display: inline;
  position: relative;
  overflow: hidden;
  padding-bottom: 4px;
  border-bottom: 1px solid black;
}

.link:hover, .link-current:hover {
  cursor: pointer;
}

.link:after {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  right: 100%;
  bottom: -5px;
  background: black;
  height: 1px;
  transition-property: left right;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}

.link:hover:after {
  right: 0;
}

/* about page */

#profile-pic {
  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1; 
  animation-fill-mode: forwards;
  padding: 3rem 5% 4rem 5%;
  width: 35%;
  height: 626.8px;
  object-fit: contain;
  
}

#main-container {
  padding-top: 7rem;
  display: flex;
  justify-content: center;
}

#about {
  opacity: 1;
  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1; 
  animation-fill-mode: forwards;
  /* animation-delay: 0.75s; */
  width: 500px;
  margin: 4rem 10% 0 0;
}

#hi {
  padding-top: 2rem;
  margin: 0;
}

#intro {
  color: #707070;
}

.fab, .fas {
  font-size: 1.5rem;
  margin: 1rem 1rem 5rem 0;
  height: auto;
  color: rgb(100,100,100);
}

.fab:hover, .fas:hover {
  transition: 0.3s;
  color: #23B6D3;
}

.box {
  padding: 0 0.5rem 0 0.5rem;
  display: inline;
  position: relative;
  overflow: hidden;
}

.box:after {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  right: 100%;
  bottom: 1px;
  height: 4rem;
  border: 1px solid black;
  /* animation: createBox 2s ease;
  animation-iteration-count: 1; 
  animation-fill-mode: forwards;
  animation-delay: 1.25s; */
}

/* projects page */
.project-container {
  margin-left: 10%;
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  padding: 9rem 0% 3rem 0%;
  animation: fadeInAnimation 2s ease;
  animation-iteration-count: 1; 
  animation-fill-mode: forwards;
  justify-content: center;
}

.project-item{
  transition: transform 0.3s ease-in-out;
  margin: 0 2rem 2rem 1rem;
  width: 400px;
  box-shadow: 0px 4px 8px rgba(0,0,0,0.1);
  border-radius: 5px;
}

.project-item:hover{
  cursor: pointer;
  transform: scale(1.05);
}

.project-item-img{
  padding: 20px;
  width: 400px;
  height: auto;
  object-fit: contain;
  text-align: center;
  border-radius: 5px 5px 0 0;
}

.project-item-header{
  color: black;
  padding-top: 1rem;
  margin: 5px 0 0 10px;
  font-size: 2rem;
}

.project-item-subtitle, .project-item-tool {
  color: #707070;
  margin: 0 0 0 10px;
  font-size: 1.2rem;
}

.project-item-tool {
  margin: 1px 0 1rem 10px;
  font-size: 1.2rem;
}

.with-outline {
  display: flex;
  padding: 5rem 15% 4rem 10%;
}

.with-outline .project {
  padding: 0;
  margin-left: 3rem;
}

.outline {
  animation: fadeInOutlineAnimation 1s ease;
  animation-iteration-count: 1; 
  animation-fill-mode: forwards;
}

.behind {
  left: 0;
  z-index: -1;
  position: fixed;
  top: 10rem;
  width: 100%;
  height: auto;
}

#girlspring-pic {
  background-color: #FFD9D9;
}

#girlspring-link {
  color: #de9090;
}

#girlspring-link:hover {
  transition-duration: 0.3s;
  color: #c96161;
}

.rapt-link {
  color: #9793cf;
}

.rapt-link:hover {
  transition-duration: 0.3s;
  color: #5a54ac;
}

.outside-link {
  color: black;
}

.outside-link:hover {
  transition-duration: 0.3s;
  color: black;
}

#search-pic {
  background-color: #c5eaf2;
}

#maps-pic {
  background-color: black;
}

#rapt-pic {
  background-color: #B4B1E4;
}

.project {
  padding: 5rem 15% 4rem 15%;
  opacity: 0;
  animation: fadeInAnimation 1s ease;
  animation-iteration-count: 1; 
  animation-fill-mode: forwards;
}

#search-header {
  border-left: 1rem solid #c5eaf2;
}

#girlspring-header {
  border-left: 1rem solid #FFD9D9;
}

#maps-header {
  border-left: 1rem solid black;
}

#universal-overview {
  border-left: 1rem solid black;
}

#rapt-header {
  border-left: 1rem solid black;
}

.project-name {
  margin: 5rem 0 0 1.5rem;
}

.project-header {
  border-left: 1rem solid black;
}

.project-heading {
  margin: 3rem 0 0 1.5rem;
}

.project-subheading {
  color: #707070;
  margin: 0 0 0 1.5rem;
  font-size: 1.3rem;
}

.project-sub-subheading {
  color: black;
  font-size: 1.3rem;
}

.project-github {
  font-size: 1rem;
  padding-top: 0.5rem;
}

.project-github a {
  color: #8A9093;
  text-decoration: underline;
}

.project-github a:visited {
  color: #404040;
}


#overview, #conclusion {
  margin: 2rem 2.5rem 2rem 2.5rem;
}

.project-text {
  color: #707070;
  margin: 2rem 4.5rem 2rem 2.5rem;
}

.project-pics {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 2rem 2rem 2rem 2rem;
}

.project-pic-half-small {
  height: 100px;
  margin: 1rem;
}

.project-pic-half-medium {
  height: 200px;
  margin: 1rem;
}

.project-pic-half-large {
  height: 250px;
  margin: 1rem;
}

.project-pic-medium {
  height: 300px;
  margin: 1rem;
}

.project-pic-large {
  height: 400px;
  margin: 1rem;
}

.project-pic-full {
  margin: 2rem 0 2rem 0;
  width: 62vw;
  height: auto;
}

.text-li {
  font-family: 'Assistant', sans-serif;
}

.bold {
  font-weight: 700;
}

.outline-pic {
  border: rgb(190, 190, 190) solid 1px;
}

/* resume */
.resume-header {
  position: relative;
}

.resume-container {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.resume-iframe {
  width: 100vh;
  height: 100%;
}


/* animations */

@keyframes fadeInAnimation { 
  0% { 
    opacity: 0; 
  } 
  100% { 
    opacity: 1; 
  } 
} 

@keyframes fadeInOutlineAnimation { 
  0% { 
    opacity: 0; 
  } 
  100% { 
    opacity: 0.5; 
  } 
} 

@keyframes fadeOutAnimation { 
  0% { 
    opacity: 1; 
  } 
  100% { 
    opacity: 0; 
  } 
} 

@keyframes createBox {
  0% { 
    right: 100%; 
  } 
  100% { 
    right: 0; 
  } 
}

/* media queries */

/* changes about section */
@media only screen and (max-width: 1100px) {
  #main-container {
    display: block;
  }
  #profile-pic {
    margin: 2rem 5% 1rem 5%;
    width: 50%;
    height: auto;
    margin-left:20%;
  }
  #about {
    width: 80%;
    margin: 0 10% 0 10%;
  }
  #hi {
    padding-top: 1.5rem;
    margin: 0;
  }
  .project {
    margin: 0;
    padding: 5rem 15% 4rem 15%;
  }
}

/* resize projects */
@media only screen and (max-width: 719px) {
  .project-container {
    padding: 11rem 10% 3rem 10%;
  }
  .project-item-img {
    width: 55vw;
  }
  .row {
    padding-top: 11rem;
  }
}

/* resize projects */
@media only screen and (max-width: 512px) {
  .project-item-img {
    padding: 1rem;
  }
  .project-item-header, .project-item-subtitle, .project-item-tool {
    margin: 0;
  }
}

/* mobile view - change top padding */
@media only screen and (max-width: 420px) {
  #profile-pic {
    margin: 4rem 5% 1rem 5%;
    width: 50%;
    height: auto;
    margin-left:20%;
  }
  .project-container {
    padding: 13rem 10% 3rem 10%;
  }
  .project-item-header{
    font-size: 1.5rem;
  }
  .project-item-subtitle, .project-item-tool {
    font-size: 1rem;
  }
  .row {
    padding-top: 12rem;
  }
}

/* mobile view - resize menu bar */
@media only screen and (max-width: 380px) {
  .box:after  {
    height: 2rem;
  }
  #name, #hi{
    font-size: 1.5rem;
  }
  .header-links{
    font-size: 1rem;
  }
  #profile-pic {
    margin: 0 5% 0 5%;
    width: 50%;
    height: auto;
    margin-left:20%;
  }
  .project-container {
    padding: 9rem 10% 3rem 10%;
  }
  .row {
    padding-top: 8rem;
  }
}

/* mobile view */
@media only screen and (max-width: 350px) {
  .project-container {
    padding: 11rem 10% 3rem 10%;
  }
  #profile-pic {
    margin: 1rem 5% 0 5%;
    width: 50%;
    height: auto;
    margin-left:20%;
  }
  .row {
    padding-top: 10rem;
  }
}

/* smallest screen size */
@media only screen and (max-width: 318px) {
  .project-container {
    padding: 13rem 10% 3rem 10%;
  }
  .row {
    padding-top: 12rem;
  }
}

/* changes individual projects */
@media only screen and (max-width: 700px) {
  .project-text {
    margin: 2rem 2.5rem 2rem 2.5rem;
  }
  .project-sub-subheading {
    margin-left: 0;
  }
  .project-pics {
    margin: 2rem 2rem 2rem 0;
  }
  .project-pic-full {
    margin: 2rem 0 2rem 0;
  }
  .project-pic-half {
    width: 50vw;
    height: auto;
  }
}

/* changes top paddings */
@media only screen and (max-width: 720px) {
  #main-container {
    padding-top: 9rem;
  }
  .project {
    padding-top: 8rem;
  }
}

/* art

.row {
  padding-top: 8rem;
  padding-bottom: 4rem;
  display: flex;
  flex-wrap: wrap;
  opacity: 0;
  animation: fadeInAnimation 1s ease;
  animation-iteration-count: 1; 
  animation-fill-mode: forwards;
}

.slide {
  width: 100%;
}

.mySlides img {
  -webkit-transition: opacity 2000ms ease-in-out;
  -moz-transition: opacity 2000ms ease-in-out;
  -o-transition: opacity 2000ms ease-in-out;
  transition: opacity 2000ms ease-in-out;
  display: block;
  overflow: auto;
  margin: auto;
  position: absolute;
  top: 0; left: 0; bottom: 0; right: 0;
}

.close {
  z-index: 150;
  color: white;
  position: absolute;
  top: 10px;
  right: 25px;
  font-size: 35px;
  font-weight: bold;
}

.close:hover, .close:focus {
  color: #999;
  text-decoration: none;
  cursor: pointer;
}

.mySlides {
  display: none;
}

.prev, .next {
  z-index: 200;
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -50px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
  -webkit-user-select: none;
}

.prev:hover, .next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

img.zoom {
  transition: 0.4s;
}

.zoom:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

.column {
  flex: 1;
  float: left; 
  width: 25%;
}

.column img {
  max-width: 80%;
  margin: 5% 5% 5% 10%;
  vertical-align: middle;
}

.row > .column {
  padding: 0 8px;
}

.modal {
  display: none;
  position: fixed;
  z-index: 3;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  overflow: auto;
  background-color: rgba(0,0,0,0.9);
}

.cursor {
  cursor: pointer;
}

.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}
.prev {
  left: 0;
  border-radius: 3px 0 0 3px;
}

img {
  margin-bottom: -4px;
}

.demo {
  opacity: 0.6;
}

.active,
.demo:hover {
  opacity: 1;
} */
